@import 'animations';

// Buttons
@mixin button {
  background-color: $color--black;
  border: none;
  color: $color--white;
  cursor: pointer;
  display: block;
  font-family: $roboto-mono_bold-font;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  white-space: nowrap;
  @if $fonts_update == true {
    @include h6();
    line-height: 36px;
  }
  @include breakpoint($bp--large-up) {
    font-family: $roboto-mono_regular-font;
    font-size: 15px;
    height: 45px;
    line-height: 45px;
    padding: 0 30px;
    @if $fonts_update == true {
      @include h6();
      line-height: 45px;
    }
  }
}

@mixin button--disabled {
  @include button;
  background: $color--gray--lightest;
  color: $color--gray--light;
}

@mixin button--underline {
  @include button;
  &:hover,
  &:visited {
    text-decoration: underline;
  }
}

// Links
@mixin link--base {
  &:not(.mantle-cta--custom-bordercolor) {
    border-bottom: 1px solid $color--black;
  }
  &:not(.mantle-cta--custom-textcolor) {
    color: $color--black;
  }
  cursor: pointer;
  display: inline-block;
  font-family: $ano-bold_regular-font;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 1;
  padding-bottom: 10px;
  position: relative;
  @if $fonts_update == true {
    @include h6();
    line-height: 1;
  }
  &:hover,
  &:active {
    &:not(.mantle-cta--custom-bordercolor) {
      border-bottom: 1px solid $color--black;
    }
    &:not(.mantle-cta--custom-textcolor) {
      color: $color--black;
    }
  }
}

@mixin link--caps {
  @include link--base;
  border-bottom: 1px solid $color--black;
  font-size: 15px;
  text-transform: uppercase;
}

@mixin link--underline {
  @include link--base;
  border-bottom: none;
  display: block;
  font-size: 12px;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
  &:hover,
  &:active {
    border-bottom: none;
    text-decoration: underline;
  }
}

@mixin breadcrumb--base {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 7px;
  line-height: 1;
  margin-bottom: 19px;
  text-transform: uppercase;
  @if $fonts_update == true {
    font-family: $helvetica-font;
  } @else {
    font-family: $roboto-mono_regular-font;
  }
}

@mixin header-search-typography($bp: true) {
  @include fontsize-rem($typesize--24px);
  font-family: $headline-font-family;
  text-transform: uppercase;
  line-height: 1;
  @if $bp {
    @include breakpoint($bp--largest-up) {
      @include fontsize-rem($typesize--30px);
    }
  }
  @if $fonts_update == true {
    @include h4();
  }
}

@mixin responsive-container {
  width: 100%;
  max-width: $site-width;
  @include swap_direction(margin, 0 auto);
}

@mixin enhanced-cart-ano-black-regular {
  font-family: $ano-black_regular-font;
  text-transform: uppercase;
}

@mixin enhanced-cart-ano-bold-regular {
  font-family: $ano-bold_regular-font;
  text-transform: uppercase;
}

@mixin enhanced-cart-helvetica-font {
  font-family: $helvetica-font;
  font-size: 15px;
  letter-spacing: 0;
}
@mixin paint-sd-checkbox(
  $border-color: $color--black,
  $bg: $color--black,
  $mark-color: $color--white
) {
  .elc-checkbox-icon {
    background: $border-color;
  }
  .elc-checkbox-check-area {
    position: relative;
    &:before {
      content: '';
      background: $bg;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 4px;
      #{$ldirection}: 4px;
    }
    &.elc-checkbox-selected {
      &:before {
        background: $mark-color;
      }
    }
  }
}

@mixin paint-filters-checkbox(
  $border-color: $color--black,
  $bg: $color--white,
  $mark-color: $color--white
) {
  &:before {
    border: 2px solid $border-color;
    background: $bg;
    color: $mark-color;
  }
  &:hover {
    &:before {
      color: $mark-color;
      border: 2px solid $border-color;
    }
  }
}

@mixin my-shades {
  display: inline-block;
  height: 60px;
  max-width: 36px;
  width: 16%;
  vertical-align: top;
  .site-my-shades--1,
  .site-my-shades--2,
  .site-my-shades--3 {
    width: 100%;
  }
  &[data-my-shades='1'] {
    .site-my-shades--1 {
      height: 100%;
    }
    .site-my-shades--2 {
      display: none;
    }
    .site-my-shades--3 {
      display: none;
    }
  }
  &[data-my-shades='2'] {
    .site-my-shades--1 {
      height: 50%;
    }
    .site-my-shades--2 {
      height: 50%;
    }
    .site-my-shades--3 {
      display: none;
    }
  }
  &[data-my-shades='3'] {
    .site-my-shades--1 {
      height: 60%;
    }
    .site-my-shades--2 {
      height: 20%;
    }
    .site-my-shades--3 {
      height: 20%;
    }
  }
}
/**
 * Generate Helper classes for slick preload items
 * prevent layout shift
 */
@mixin slider-preload-row($value, $size, $breakpoint) {
  .#{$size}-items-per-row-#{$value}:not(.content-block--grid-layout):not(.content-block--flex-layout)
    > div:not(.slick-list) {
    @if $breakpoint {
      :not(.slick-list) & {
        @include breakpoint($breakpoint) {
          display: none;
        }
      }

      &:nth-child(-n + #{$value}) {
        :not(.slick-slist) & {
          @include breakpoint($breakpoint) {
            display: flex;
          }
        }
      }
    } @else {
      :not(.slick-list) & {
        display: none;
      }

      &:nth-child(-n + #{$value}) {
        :not(.slick-list) & {
          display: flex;
        }
      }
    }
  }
}
/**
 * Generate Helper classes for grid items
 */
@mixin grid-row($value, $size, $breakpoint) {
  .#{$size}-items-per-row-#{$value} {
    @if $breakpoint {
      @include breakpoint($breakpoint) {
        grid-template-columns: repeat($value, 1fr);
      }
    } @else {
      grid-template-columns: repeat($value, 1fr);
    }
  }
}

// Welcome 15 Offer popup styles
@mixin welcome15__offer {
  .welcome-email {
    color: $color--white;
    height: 322px;
    overflow: hidden;
    margin-bottom: 20px;
    .site-email-signup {
      &__success {
        @include swap_direction(padding, 1em);
        background-color: transparent;
        height: inherit;
        &--welcome-email {
          .site-logo {
            &__image {
              @include swap_direction(margin, 0 auto);
              height: 60px;
              max-width: 120px;
              display: block;
            }
          }
        }
        &-header {
          font-size: 36px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;
          text-transform: uppercase;
          font-family: $ano-bold_regular-font;
        }
        &-offer {
          font-size: 15px;
          letter-spacing: normal;
          line-height: 1.2em;
          padding-bottom: 18px;
          text-align: center;
          text-transform: none;
        }
        &-terms {
          font-size: 13px;
          line-height: 0.6;
          margin-bottom: 0;
          margin-top: 1em;
          text-align: center;
          width: 100%;
          .site-email-signup {
            &__terms-details {
              width: 60%;
              float: $ldirection;
              line-height: normal;
            }
          }
          a.terms-conditions-link {
            color: $color--white;
            font-size: 11px;
            line-height: normal;
            text-transform: initial;
            font-family: $roboto-condensed-regular-font;
          }
        }
      }
      &__terms-details {
        p {
          @include swap_direction(margin, 0);
          font-family: $roboto-condensed-regular-font;
        }
      }
    }
    .site-email-signup__success--welcome-email {
      form {
        text-align: $ldirection;
        .email_input {
          @include swap_direction(margin, 0 auto);
          width: 90%;
          input[type='email'] {
            @include swap_direction(padding, 4px 0 0 10px);
            @include swap_direction(margin, 0);
            color: $color--gray--message;
            text-align: $ldirection;
            &.form-text {
              width: 60%;
              float: $ldirection;
              margin-#{$rdirection}: 5%;
              text-transform: uppercase;
            }
          }
          input[type='text'],
          input[type='email'],
          input[type='number'],
          input[type='submit'],
          input[type='button'],
          button {
            display: block;
            height: 33px;
            width: 236px;
          }
          input[type='submit'] {
            &.form-submit {
              float: $ldirection;
              width: 30%;
              line-height: normal;
              color: $color--black;
              @if $fonts_update == true {
                margin-top: 0;
                margin-bottom: 0;
              }
            }
          }
          input[type='submit'],
          input[type='button'],
          button {
            background: $color--pink;
            border: 1px solid $color--white;
          }
        }
      }
    }
    &.error-msg {
      .site-email-signup__success-offer--line1 {
        padding-bottom: 2px;
      }
    }
    .popup-offer__sign-up-form-error-messages {
      color: $color--red;
      font-size: 12px;
      text-align: center;
      text-transform: uppercase;
    }
  }
  /* Welcome15 success popup */

  .welcome-email.success {
    padding: 20px 20px 0px 20px;
    .site-email-signup {
      &__success {
        @include swap_direction(padding, 3.5em 0 0 1.5em);
        &-site-logo {
          &__image {
            @include swap_direction(margin, 0 auto);
            height: 60px;
            max-width: 120px;
            display: block;
          }
        }
        &-header {
          font-size: 42px;
          font-weight: bold;
          letter-spacing: -1.5px;
          line-height: 40px;
          text-transform: none;
          padding-bottom: 20px;
        }
        &-offer {
          font-size: 26px;
          letter-spacing: -0.5px;
          line-height: 1.1em;
          padding-bottom: 0;
          &__code {
            font-weight: bold;
          }
        }
        &-header {
          font-size: 36px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;
          text-transform: uppercase;
          font-family: $ano-bold_regular-font;
        }
        &-cta {
          text-align: center;
          a {
            @include swap_direction(padding, 4px);
            border: 1px solid $color--white;
            background: $color--white;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 20px;
            cursor: pointer;
            font-family: $ano-bold_regular-font;
          }
        }
        &-offer {
          font-size: 16px;
          letter-spacing: normal;
          line-height: 1.1em;
          padding-bottom: 18px;
          text-align: center;
          text-transform: initial;
        }
        &-reminder,
        &-terms {
          font-size: 13px;
          line-height: 0.6;
          margin-bottom: 0;
          text-align: center;
          width: 100%;
        }
        &-reminder {
          margin-top: 1em;
        }
        &-terms {
          margin-top: 1.5em;
          .site-email-signup {
            &__terms-details {
              width: 60%;
              float: $ldirection;
            }
          }
          a.terms-conditions-link {
            color: $color--white;
            font-size: 11px;
            text-transform: initial;
            font-family: $roboto-condensed-regular-font;
          }
        }
      }
    }
  }
  /* Colorbox overrides */

  .welcome15 {
    #colorbox {
      #cboxWrapper {
        #cboxContent {
          #cboxClose::before {
            color: $color--white;
            font-size: 12px;
            font-weight: bold;
            line-height: 12px;
            display: block;
            content: '\2716';
            top: 10px;
            #{$rdirection}: 1px;
          }
          #cboxLoadedContent {
            @include swap_direction(padding, 0);
            background: transparent;
            background-color: $color--black;
          }
        }
      }
    }
  }
}

// checkout related mixins

// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $ano_regular-font;
  font-size: get-rem(30px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $ano_regular-font;
  font-size: get-rem(18px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

@mixin checkout-sub-heading--medium--figma {
  font-family: $ano-bold_regular-font;
  font-size: get-rem(20px);
  font-weight: normal;
  letter-spacing: 0;
  line-height: get-line-height(20px, 24px);
  @include breakpoint($bp--large-up) {
    font-size: get-rem(25px);
    line-height: get-line-height(25px, 28px);
  }
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $ano_regular-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $ano-bold_regular-font;
  font-size: get-rem(12px);
  letter-spacing: 1.7px;
  line-height: get-line-height(12px, 20px);
  text-transform: uppercase;
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $ano_regular-font;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $ano-bold_regular-font;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $ano_regular-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 14px);
}

@mixin slider-header-product-title {
  background-color: transparent;
  color: $color--black;
  font-family: $ano-bold_regular-font;
  font-size: get-rem(17px);
  letter-spacing: 0;
  line-height: get-line-height(13px, 17px);
  text-transform: uppercase;
}

@mixin slider-footer-disclaimer-text {
  font-size: get-rem(13px);
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}
@mixin low-contrast-shadow {
  box-shadow: inset 0 0 0 0.5px $color-grey-lowcontrast;
}

@mixin spinner() {
  position: absolute;
  inset-block-start: -1px;
  inset-inline-start: -1px;
  content: ' ';
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  border-color: $color--white transparent transparent transparent;
  animation: spin 1.5s linear infinite;
}

@mixin primary-wrapper--pill {
  border: 1px solid $color-grey-lowcontrast;
  block-size: 45px;
  padding-block: 8px;
  padding-inline: 24px;
  border-radius: 25px;
}

@mixin primary-select {
  @include primary-wrapper--pill;
  padding-inline: 24px 34px;
  background: url('#{$base-theme-path}svg-icons/src/icon--chevron-down.svg') no-repeat;
  background-size: 14px;
  background-position: calc(100% - 13px) 50%;
  text-decoration: none;
  [dir='rtl'] & {
    background-position: 13px 50%;
  }
}

//Hide Cart, Order Summary, Shipping & Payment Panel in Checkout/index page
@mixin checkout_index_hide_section {
  .active-panel-signin {
    .checkout {
      .checkout__content {
        @include breakpoint($bp--medium-up) {
          width: 100%;
        }
        section {
          &.shipping-panel,
          &.payment-panel {
            &.collapsed {
              display: none;
            }
          }
          &.sign-in-paypal-panel {
            display: none;
          }
        }
      }
      .checkout__sidebar {
        display: none;
      }
      .messages {
        background: none;
        border: none;
        [class*='error_offer_criteria_'] {
          display: none;
        }
      }
    }
  }
  #confirmation-page {
    .checkout__sidebar {
      #need-help-panel {
        background: $color--black;
        color: $color--full-white;
        padding: 1em;
        a {
          color: $color--full-white;
          text-transform: inherit;
        }
        .font--malone {
          font-size: get-rem(20px);
          letter-spacing: 0;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}
