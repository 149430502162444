@import '../../../../scss/theme-bootstrap.scss';

.content-block {
  &--slider {
    &:not(.slick-slide) {
      display: grid;
    }
  }
  &--grid {
    margin: 0 auto;
    &:not([class*="padding-top-"]) {
      padding-top: 60px;
    }
    &.slick-slider {
      &[data-is-slider='1'] {
        display: flex;
        // Space required for dots.
        padding-block-end: 30px;
        @include breakpoint($bp--xlarge-up) {
          padding-block-end: 0;
        }
      }
      .slick-slide {
        display: flex;
        flex: 1;
        height: auto;
        &.slide-wrapper {
          flex-wrap: wrap;
        }
        &.content-block--grid-item {
          display: block;
        }
      }
    }
    &[data-items-per-slide] {
      .content-block--grid-item {
        width: 50%;
      }
    }
    &[data-items-per-slide='1'] {
      .content-block--grid-item {
        width: 100%;
      }
    }
    .sd-product-grid {
      padding: 0;
      .elc-slider-view-wrapper {
        .slick-list {
          width: 100%;
          margin: 0;
        }
        .slick-slider {
          padding-block-start: 32px;
          .slick-prev,
          .slick-next {
            top: -40px;
            #{$rdirection}: 20px;
            &.slick-disabled {
              opacity: 0.3;
            }
          }
          .slick-prev {
            #{$rdirection}: 95px;
          }
          @if $text-direction == 'rtl' {
            .slick-next {
              #{$rdirection}: 75px;
            }
            .slick-prev {
              #{$rdirection}: 0;
            }
          }
          .elc-slider-dots-list {
            .elc-slider-dot {
              margin: 0 2px;
              height: 8px;
              width: 8px;
              .elc-slider-dot-button {
                height: 8px;
                width: 8px;
                &::before {
                  opacity: 1;
                  position: static;
                  border: 1px solid $color--black;
                  border-radius: 10px;
                  background-color: transparent;
                  color: transparent;
                }
                &-active {
                  &::before {
                    background-color: $color--black;
                  }
                }
              }
            }
          }
        }
        &.elc-product-shade-picker-slider {
          .slick-list {
            width: 180px;
            margin-inline-start: 4px;
          }
          .slick-slider {
            padding-top: 0;
            .slick-prev,
            .slick-next {
              top: 0;
              margin-block-start: 3px;
              #{$rdirection}: 0;
            }
            .slick-prev {
              #{$rdirection}: unset;
              #{$ldirection}: 0;
            }
            @if $text-direction == 'rtl' {
              .slick-next {
                #{$rdirection}: auto;
              }
              .slick-prev {
                #{$ldirection}: unset;
              }
            }
          }
        }
      }
      .padding-right-20px & {
        .elc-slider-view-wrapper {
          .slick-next {
            #{$rdirection}: 0;
          }
          .slick-prev {
            #{$rdirection}: 75px;
          }
          @if $text-direction == 'rtl' {
            .slick-next {
              #{$rdirection}: 75px;
            }
            .slick-prev {
              #{$rdirection}: 0;
            }
          }
        }
      }
    }
  }
  &--eyebrow,
  &--header {
    margin: 0 auto;
    position: relative;
  }
  &-formatter {
    display: flex;
  }
  // have CMS options for top / bottom, so only align center
  &-formatter,
  &--content {
    margin-#{$rdirection}: auto;
    margin-#{$ldirection}: auto;
    position: relative;
    width: 100%;
  }
  &--background {
    position: absolute;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    width: 100%;
    overflow: hidden;
  }
  &--eyebrow {
    .style--bare {
      @include sub-title--bold;
    }
    &:not(.text-color--black) {
      .dark-theme & {
        color: $color--white;
      }
    }
  }
  &--header {
    .style--bare {
      @include title-01;
    }
    &:not(.text-color--black) {
      .dark-theme & {
        color: $color--white;
      }
    }
    p {
      margin: 0;
    }
    &.is-slider {
      .mantle-custom-text {
        @include breakpoint($bp--medium-up) {
          width: calc(100% - 128px);
        }
      }
    }
  }
  &-formatter {
    &.dark-theme {
      .sd-product-grid {
        .elc-slider-view-wrapper {
          .slick-slider {
            .elc-slider-dots-list {
              .elc-slider-dot {
                .elc-slider-dot-button {
                  &::before {
                    border-color: $color--white;
                  }
                  &-active {
                    &::before {
                      background-color: $color--white;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .slick-slider {
        .slick-dots {
          li {
            button::before {
              border-color: $color--white;
            }
            &.slick-active {
              button::before {
                background-color: $color--white;
              }
            }
          }
        }
      }
    }
    .slick-slider {
      .slick-list {
        width: 100%;
        margin: 0;
      }
      .slick-prev,
      .slick-next {
        top: -45px;
      }
      .slick-dots {
        li {
          margin: 0 2px;
          height: 8px;
          width: 8px;
          button {
            height: 8px;
            width: 8px;
            &::before {
              opacity: 1;
              position: static;
              border: 1px solid $color--black;
              border-radius: 10px;
              background-color: transparent;
              color: transparent;
            }
          }
          &.slick-active {
            button::before {
              background-color: $color--black;
            }
          }
        }
      }
      .comparison-item,
      .brief-product-arrows {
        .slick-prev,
        .slick-next {
          top: 10px;
          @include breakpoint($bp--medium-up) {
            top: 3px;
          }
          @if $text-direction == 'rtl' {
            @include breakpoint($bp--medium-up) {
              top: -1px;
            }
          }
        }
      }
    }
  }
}
$grid-gap: 0 8px 16px 20px 24px 32px;

.content-block {
  &--grid-layout {
    display: grid;
  }
  &--flex-layout {
    display: flex;
    flex-wrap: wrap;
    .block-align-left & {
      justify-content: start;
    }
    .block-align-center & {
      justify-content: center;
    }
    .block-align-right & {
      justify-content: end;
    }
  }
  &--grid-layout,
  &--flex-layout {
    @each $value in $grid-gap {
      &.mobile-grid-gap-#{$value} {
        grid-gap: #{$value};
      }
    }
    @each $value in $grid-gap {
      &.grid-gap-#{$value} {
        &:not([class*='mobile-grid-gap-']) {
          grid-gap: #{$value};
        }
        @include breakpoint($bp--large-up) {
          grid-gap: #{$value};
        }
      }
    }
    .content-block--grid-item {
      > div {
        display: flex;
        height: 100%;
      }
    }
  }
}
// Breakpoints helpers
$items-per-row: 1 2 3 4 5 6;

@each $value in $items-per-row {
  @include grid-row($value, 'mobile', false);
  @include grid-row($value, 'desktop', $bp--xlarge-up);
  @include grid-row($value, 'large', $bp--xxxlarge-up);
  @include grid-row($value, 'extra_large', $bp--wide-up);
}
// Peeking units
$peeking-units: (
  '.05': 0.05,
  '.25': 0.25,
  '.35': 0.35,
  '.45': 0.45,
  '.50': 0.50,
  '.75': 0.75,
);

// Build Peeking classes, avoid CLS and supports element height on comparison
@each $value in $items-per-row {
  .content-block--slider.mobile-items-per-row-#{$value} {
    @each $k, $v in $peeking-units {
      &[data-peeking="#{$k}"] {
        .slick-list:not([class*='padding-#{$rdirection}-']) {
          padding-inline-end: calc(100% / #{$value} * #{$v});
        }
      }
    }
  }
}

@each $value in $items-per-row {
  .content-block--slider.desktop-items-per-row-#{$value} {
    @each $v in $peeking-units {
      &[data-peeking="#{$v}"] {
        .slick-list:not([class*='padding-#{$rdirection}-']) {
          @include breakpoint($bp--medium-up) {
            padding-inline-end: calc(100% / $value * $v);
          }
        }
      }
    }
  }
}

@each $value in $items-per-row {
  .content-block--slider.large-items-per-row-#{$value} {
    @each $v in $peeking-units {
      &[data-peeking="#{$v}"] {
        .slick-list:not([class*='padding-#{$rdirection}-']) {
          @include breakpoint($bp--xlarge-up) {
            padding-inline-end: calc(100% / $value * $v);
          }
        }
      }
    }
  }
}

@each $value in $items-per-row {
  .content-block--slider.extra-large-items-per-row-#{$value} {
    @each $v in $peeking-units {
      &[data-peeking="#{$v}"] {
        .slick-list:not([class*='padding-#{$rdirection}-']) {
          @include breakpoint($bp--xxlarge-up) {
            padding-inline-end: calc(100% / $value * $v);
          }
        }
      }
    }
  }
}

.content-block--content[class*="padding-top-"] .content-block--grid:not(.content-block--slider) {
  padding-top: 0;
}
