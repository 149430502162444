/* ==========================================================================
   Global Variables - Colors
   ========================================================================== */

/*
 * Here's a great tutorial on how to use color variables properly:
 * @see http://sachagreif.com/sass-color-variables/
 */

$color--white: hsl(0, 0%, 100%) !default;
$color--white--opacity-1: rgba(255, 255, 255, 0.1);
$color--white--opacity-3: rgba(255, 255, 255, 0.3);
$color--white--opacity-4: rgba(255, 255, 255, 0.4);
$color--white--opacity-8: rgba(255, 255, 255, 0.8);
$color--white--opacity-9: rgba(255, 255, 255, 0.9);
$color--transparent: hsla(0, 0%, 100%, 0) !default;
$color--gray--lighter-white: hsl(0, 0%, 98%) !default;
$color--gray--white: hsl(0, 0%, 94%) !default;
$color--gray--lightest: hsl(0, 0%, 85.9%) !default;
$color--gray--lighter: hsl(0, 0%, 78%) !default;
$color--gray--light: hsl(0, 0%, 60%) !default;
$color--gray: hsl(228, 2.3%, 41.8%) !default;
$color--gray--dark: hsl(0, 0%, 30%) !default;
$color--gray--darker: hsl(0, 0%, 20%) !default;
$color--gray--darkest: hsl(0, 0%, 16%) !default;
$color--gray--medium: hsl(0, 0%, 64%) !default;
$color--gray--black: hsl(0, 0%, 10%) !default;
$color--gray--message: hsl(0, 0%, 60.8%) !default;
$color--black: hsl(0, 0%, 0%) !default;
$color--hot--brown: hsl(1, 44%, 50%) !default;
$color--red: hsl(0, 100%, 50%) !default;
$color--red--dark: hsl(0, 100%, 49%) !default;
$color--red--light: hsl(9.9, 87.7%, 55.3%) !default;
$color--dark-red: #d2232a;
$color--brick-red: #e93323;
$color--disabled: hsl(60, 15%, 91%) !default;
$color--light--black: #191919;
$color--dark--black: #121212;
$color--black--light: rgba(0, 0, 0, 0.88);
$color--black--opacity: rgba(25, 25, 25, 0.9);
$color--black--opacity-6: rgba(25, 25, 25, 0.6);
$color--black--transparent: rgba(25, 25, 25, 0.5);
$color--gray-transparent: hsla(0, 0%, 50%, 0.7);
$color--blue--dark: hsl(220, 45.9%, 47.8%) !default;
$color--blue: hsl(240, 100%, 50%) !default;
$color--blue--light: #5f93ec !default;
$color--blue--lighter: hsl(212.6, 72.9%, 59.4%) !default;
$color--blue--sky: #d0e4f5 !default;
$color--black--opacity-02: rgba(0, 0, 0, 0.02);
$color--black--opacity-05: rgba(0, 0, 0, 0.05);
$color--black--opacity-10: rgba(0, 0, 0, 0.1) !default;
$color--black--opacity-9: rgba(0, 0, 0, 0.9);
$color--black--opacity-30: rgba(0, 0, 0, 0.3);
$color--white--opacity-30: rgba(255, 255, 255, 0.3);
$color--dim--gray: rgb(114, 114, 114);
$color--red--error: rgb(184, 73, 71);
$color--red--transparent: rgba(220, 53, 48, 0.05);
$color--fb--blue: #1877f2;

$color--gray--thin: #fbfbfb;
$color--gray--thick: #f0f0f0;
$color--gray--tier4: #fcfcfc;
$color--gray--tier5: #e0e0e0;
$color--gray--dark: #949494;
$color-success: #087443 !default;
$color-error: #a80000 !default;
$color-alert: #b54708 !default;
$color--faux--sandy--brown: #ee9f57;
$color--green: hsl(120, 100%, 25%) !default;
$color--green-light: hsl(116, 44%, 49%) !default;
$color-dusty-grey: rgb(153, 153, 153);
$color-smoke-grey: rgb(253, 253, 253);

$color--overlay: hsla(0, 0%, 0%, 0.45) !default;
$color--error--overlay: hsla(0, 0%, 0%, 0.75) !default;
$color--gray--medium--dark: hsla(0, 0%, 33%, 1) !default;
$color--hot--brown: hsla(1, 44%, 50%, 1) !default;

$color--chestnut--red: hsla(1, 44%, 50%, 1) !default;
$color--chestnut--brown: #592a17 !default;

$white: $color--white !default;
$black: $color--black !default;

// LUX colors
$lux-success: #44826e; //rgba(68, 130, 110)
$lux-success-background: #eefbf7; //rgba(238, 251, 247)
$lux-success-background-opacity-60: rgba($lux-success-background, 0.6); //#eefbf799
$lux-success-border: #99dfc7; //rgba(153, 223, 199)
$lux-info-icon: #bb8b60;
$lux-field-label: #757575;
$color-facebook-blue: rgba(59, 89, 152, 0.88);
$color-danube-blue: #5f7cbb;
$warning-message-border: #feee95;
$warning-message-background: #fefbe6;
$lux-error: #b00020; //rgba(176, 0, 32)
$lux-border-color: #e6e6e6;
$lux-panel-border: #d4d4d4;
$lux-alert-error-msg: #f9eeee;
$lux-alert-error-border: #e9bfbf;
$lux-alert-success-msg: #f0f9f5;
$lux-alert-success-border: #bee3d2;
$lux-alert-info-msg: #e66100;

// Semantic Colors
$color--text: $color--black !default;
$color--link: $color--black !default;
$color--border: $color--gray--light !default;
$color--border-active: $color--black !default;
$bg-color: $color--white !default;
$dark-bg-color: #1b1b1b;

$color--title-red: hsl(350, 68%, 45%) !default;

// Emphasis Colors
$color--information: hsl(199, 49%, 45%) !default;
$color--success: hsl(120, 32%, 40%) !default;
$color--error: hsl(1, 44%, 50%) !default;
$color--warning: hsl(37, 46%, 53%) !default;
$color--warning-bright: hsl(29, 82%, 64%) !default;

$color--pink-tier1: #f8b8c5 !default;
$color--pink-tier2: #fc9aff !default;
$color--light-pink: #f7b7d3 !default;
$color--red-tier2: #ed2024 !default;
$color--red-shade: #ed2025 !default;
$color--gray-tier3: #8f9397 !default;
$color--dark-grey-tier3: #575555;
$color--dark-gray: #bfbfc3 !default;
$color--darker-gray: #767676;
$color--full-white: #fff !default;
$color--gray-warning: hsl(0, 0%, 53%) !default;
$color--pink: hsl(300%, 100%, 80%) !default;

$border: 1px solid $color--gray--lighter !default;
$color--gray--dusty: #999;
$color--black--opacity-64: rgba(0, 0, 0, 0.64);
$color--black--opacity-88: rgba(0, 0, 0, 0.88);
$color--bg--black--transparent: rgba($black, 0);
$color--bg--white--transparent: rgba($white, 0);
$color--gray--tier2: #e0e0e0;
$color--nero: #212121;
$color--snow: #fbfbfb;
$color--orange--light: rgba(238, 159, 87, 0.2);
$color--orange--dark: #ee9f57;
$cookie--link--active: #4285f4;
$color--bg--carmine--transparent: rgba($lux-error, 0.02);
$color--chambray: #3c5a99;
$color--steel--blue: #5f7cbb;
$color--chetwode--blue: #8c9dda;
$color--cyan--blue: #4680c2;
$color--black--opacity-54: rgba(0, 0, 0, 0.54);

// Enhanced checkout
$color--gray--checkout: #aaa;
$color--gray--light--checkout: #eee;
$color--gray--border: #aeaeae;
$color--red--dark--checkout: #b00020;
$color--black--opacity-6--checkout: rgba(0, 0, 0, 0.6);
$color--light--orange: #ee9f57;
$color--mostly--white: #fffdfc;
// Loyalty related colors
$color--pink--light: #f6b7d2;
$color--red--light: #ec2124;
$color--grey--rewards: #171212;
$color--pink-light: #ee9bff !default;
$color--pink-thin: #b978c7 !default;
$color--black--opacity-4--checkout: rgba(0, 0, 0, 0.4);
$color--green--medium: #70b000;
$color--green--light: #f7fdf4;
$color--gray--dusty--disable: #929292;
$color--gray--background: #eff1f0;
$color--mercury: #e6e6e6;
$color--black--opacity-28: rgba(28, 28, 28, 1);
$color--error--red: #d00;
$color--very--light--grey: #c7c7c7;
$color--yellow: #ff0;
$color-purple-dark: #a40079;

// 2021 style refresh
// NEW color vars
// Editorial colors
$color-orange: #f1af38 !default;
$color-tan: #deb899 !default;
$color-blue: #346bf6 !default;
$color-green: #47ff7f !default;
$color-pink: #f098f0 !default;
$color-red: #dc3530 !default;
$color-purple: #732d5c !default;
$color-chartreuse: #cdff0f !default;
$color-gold: #ad8b45 !default;
// Shades
$color-red-shade: #a51b15 !default;
$color-pink-shade: #d3707e !default;
$color-orange-shade: #e49263 !default;
$color-nude-shade: #c05d59 !default;
$color-berry-shade: #572a41 !default;
$color-blue-shade: #171aca !default;
$color-champagne: #fbe5d7 !default;
$color-purple-shade: #761133 !default;
$color-brown-shade: #af7e64 !default;
$color-coral-shade: #f75753 !default;
$color-bold-shade: #201d4a !default;
$color-beige-shade: #ffd8be !default;
$color-gold-shade: #d0956d !default;
$color-copper-shade: #ca9b87 !default;
$color-neutral-shade: #edd0b2 !default;
$color-white-shade: #e8e0da !default;
$color-yellow-shade: #fbc856 !default;
$color-bronze-shade: #d78148 !default;
$color-green-shade: #c2c955 !default;
$color-silver-shade: #a5a3a4 !default;
$color-black-shade: #1e1b1d !default;
$color-shade-name: #737373 !default;

// System and element colors
// CTA
$color-cta-black: #000 !default;
$color-cta-white: #fff !default;
$color-cta-grey: #ccc !default;
$color-cta-grey--opacity-20: rgba(204, 204, 204, 0.2) !default;
$color-cta-grey--light: #f9f9f9 !default;
$color--grey-bg: $color-cta-grey--light !default;
$color--link-grey: #68696d !default;
$color--tos-red: #db2f41 !default;
// fills and elements
$color-grey-fill: #f5f3f3 !default;
$color-hr: rgba(0, 0, 0, 0.15) !default;
$color-grey-fill-mpp: #f0f1f3 !default;
$color-mint-afterpay: #b2fce4 !default;
// typography specific colors
$color-grey-fill-mpp: $color-cta-grey--light !default;
//Bopis update colors
$red: #f00 !default;
$brown: #392720 !default;
$gray: #a39e9d !default;
$light-gray: #cdcbc9 !default;
$color-dark-red: #b00020 !default;
$color-apple-green: #88ae2e;
$color-light-warm-grey: #f0f0f0 !default;
$color-off-black: #120e02 !default;
$color-bopis-error: $color-dark-red;

// Typography specific colors.
$color-text-grey: #727272 !default;
$color-text-red: #f00 !default;
$color--black--opacity-25: rgba(0, 0, 0, 0.25) !default;
$color-purple-light: #df97ff !default;

$color--black--opacity-12: rgba(0, 0, 0, 0.12);
$color--black--opacity-14: rgba(0, 0, 0, 0.14);
$color--black--opacity-2: rgba(0, 0, 0, 0.2);
$color--black--opacity-24: rgba(0, 0, 0, 0.24);
$color--black--opacity-3: rgba(0, 0, 0, 0.3);
$color--black--opacity-87: rgba(0, 0, 0, 0.87);
$color--black--opacity-88: rgba(0, 0, 0, 0.88);
$color--white-azure: #eff0f0;
$color--blue-dark: rgb(4, 10, 43);
$color--green-christi: #70a912;
$color--gray-light: #bababa;
$color--gray-dark: rgb(18, 14, 2);
$color--nero--thin: #1a1a1a;
$color--nobel--dark: #a39e9d;

$color-grey-lowcontrast: #737373;
$color-grey-highcontrast: #d0d0d0;
$color-border-grey: #e8e7e7;
$color-border-lightgrey: #dbdbdb;
$color-dusty-grey: #9c9c9c;
$color-darkest-grey: #222;
$color-darkest-grey-2: #3a3a3a;
$color-silver-grey: #cacaca;
$color--white--solitude: #f3f4f6;
$color--blue--solitude: #e8f0fe;
$color--gray--whisper: #ededed;
$color--green--scream: #47ff7e;
$color--green--neon: #3fff74;
$color--green--emerald: #39cc60;
$color-zambezi: #585858;
$lux-submit-button: #f94b6e;
$color-medium-green: #70b000;
$color-blue-dark: #040a2b;
$color-magenta-red: #fe20d7;
$color-magenta-shadow: #e80078;
$color--yellow-shade: #fcfa17;
$color--chetwode-light: #caeaf7;
$color--tmo-red: #db2514;
$color--tmo-gray: #ccc;
$color--tmo-green: #008500;
$color--tmo-light-green: #f5fff7;
$color--tmo-light-gray: #dadada;
$color--tmo-light-pink: #cc74df;
$color--tmo-light-aqua: #23c2ab;
$color--tmo-thin-aqua: #14bfb9;
$color--tmo-grey-scale-medium: #848484;

// Loyalty colors
/* stylelint-disable max-line-length */
// Bronze tier
$color--bronze-generic: #bc8888;
$color--bronze-bg: #bc8888;
$color--bronze-border-top: #ac7171;
$color--bronze-border-right: #935555;
$color--bronze-border-bottom: #a36868;
$color--bronze-border-left: #c18b8b;
$color--bronze-progress-bar: linear-gradient(90deg, #efadad 0%, #e7a7a7 9%, #cf9696 43%, #c18b8b 74%, #bc8888 100%);

// Silver tier
$color--silver-generic: #a1a1a1;
$color--silver-bg: #a1a1a1;
$color--silver-border-top: #a1a1a1;
$color--silver-border-right: #959595;
$color--silver-border-bottom: #b4b4b4;
$color--silver-border-left: #bdbdbd;
$color--silver-progress-bar: linear-gradient(90deg, #bdbdbd 0%, #b5b5b5 9%, #9d9d9d 43%, #8f8f8f 74%, #8a8a8a 100%);

// Gold tier
$color--gold-generic: #c29b4e;
$color--gold-bg: #96783d;
$color--gold-border-top: #96783d;
$color--gold-border-right: #8e6e33;
$color--gold-border-bottom: #ab853e;
$color--gold-border-left: #c29b4e;
$color--gold-progress-bar: linear-gradient(97.57deg, #e0b35a 8.46%, #d8ad56 18.41%, #c09a4d 56.03%, #b28e47 90.33%, #ad8b45 119.09%);
/* stylelint-enable max-line-length */
