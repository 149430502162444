// RTL support
@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}

// flip for RTL support
// if $rdirection==left (i.e. we're in RTL mode) => x *= -1
@function flipX($v, $rdirection) {
  @if $rdirection == left {
    @return -1 * $v;
  } @else {
    @return $v;
  }
}

// scss-lint:disable ImportantRule
@mixin hide() {
  display: none;
}

// Hide from both screenreaders and browsers: h5bp.com/u
@mixin hidden() {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v
@mixin visuallyhidden() {
  @include swap_direction(border, 0);
  clip: rect(0 0 0 0);
  height: 1px;
  @include swap_direction(margin, -1px);
  overflow: hidden;
  @include swap_direction(padding, 0);
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p
  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    @include swap_direction(margin, 0);
    overflow: visible;
    position: static;
    width: auto;
  }
}

@mixin reset-button() {
  @include swap_direction(border, none);
  display: block;
  @include swap_direction(padding, 0);
  background: none;
}

// Fill the entire space of a relatively positioned parent
@mixin fill-rel-parent() {
  position: absolute;
  top: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  bottom: 0;
  display: block;
}

@mixin remove-clearfix() {
  &.clearfix:after {
    content: '' !important;
    display: none !important;
    height: auto !important;
    min-height: auto !important;
    clear: none !important;
    visibility: hidden !important;
  }
}

@mixin clear-drupal-wrappers() {
  .node {
    @include remove-clearfix();
    display: inline;
  }
  .content {
    display: inline;
  }
  .block {
    display: inline;
  }
}

@mixin dash-before() {
  &:before {
    @include pseudo-base();
    @include body-mono();
    content: '-';
  }
}

// Basic styles for almost all pseudo
@mixin pseudo-base() {
  content: '';
  display: block;
  z-index: $z-low;
}

// Mobile/desktop show/hide classes. Turned into classes in global/_visibility-classes.scss
@mixin show-medium-up--block() {
  display: none;
  @include breakpoint($bp--largest-up) {
    display: block;
  }
}

@mixin show-xlarge-up--block() {
  display: none;
  @include breakpoint($bp--xlarge-up) {
    display: block;
  }
}

@mixin show-medium-up--inline() {
  display: none;
  @include breakpoint($bp--largest-up) {
    display: inline;
  }
}

@mixin show-xlarge-up--inline() {
  display: none;
  @include breakpoint($bp--xlarge-up) {
    display: inline;
  }
}

@mixin hide-medium-up() {
  @include breakpoint($bp--largest-up) {
    display: none;
  }
}

@mixin hide-xlarge-up() {
  @include breakpoint($bp--xlarge-up) {
    display: none;
  }
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  transform: $transform;
}

@mixin grid-debugger($show-classes: false, $show-note: false) {
  // debugger
  counter-reset: grid-item;
  > * {
    outline: dotted 1px yellow;
    position: relative;
    &:hover {
      &:before,
      &:after {
        background: hsla(0, 0%, 0%, 0);
        font-size: 1px;
      }
    }
    &:before {
      content: counter(grid-item);
      counter-increment: grid-item;
      color: white;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      z-index: 1;
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      bottom: 0;
      background: hsla(0, 0%, 0%, 0.5);
      padding-top: 15px;
    }
    @if $show-classes == true {
      &:after {
        content: 'class: ' attr(class);
        position: absolute;
        top: 70px;
        font-size: 18px;
        color: white;
        text-align: center;
        z-index: 2;
        display: block;
        width: 100%;
      }
    }
    @if $show-note == true {
      &:after {
        content: attr(data-debug-note);
        position: absolute;
        top: 70px;
        font-size: 18px;
        color: white;
        text-align: center;
        z-index: 2;
        display: block;
        width: 100%;
      }
    }
  }
}

@mixin vertically-center($horizontally-center: false, $absolute-positioning: false, $undo: false) {
  top: 50%;
  @include transform-style(preserve-3d);
  @if ($absolute-positioning) {
    position: absolute;
  } @else {
    position: relative;
  }
  @if ($horizontally-center) {
    #{$ldirection}: 50%;
    #{$rdirection}: auto;
    @include transform(translateX(flipX(-50%, #{$rdirection})) translateY(-50%));
  } @else {
    @include transform(translateY(-50%));
  }
  @if ($undo) {
    position: static;
    #{$ldirection}: auto;
    @include transform(none);
  }
  .no-csstransforms & {
    //@todo IE8 support
  }
}

/* Mixin - Arrow
   ========================================================================== */
/*
 * @params
 *  $color
 *  $width
 *  $height
 *  $direction
 */

@mixin arrow($color: black, $width: 10px, $height: 10px, $direction: down) {
  margin-top: (-0.5 * $height);
  border-style: solid;
  border-color: transparent;
  @if $direction == left {
    border-top-width: round($height / 2);
    border-bottom-width: round($height / 2);
    border-#{$ldirection}-width: 0;
    border-#{$rdirection}-width: $width;
    border-#{$rdirection}-color: $color;
  } @else if $direction == right {
    border-top-width: round($height / 2);
    border-bottom-width: round($height / 2);
    border-#{$rdirection}-width: 0;
    border-#{$ldirection}-width: $width;
    border-#{$ldirection}-color: $color;
  } @else if $direction == up {
    border-#{$ldirection}-width: round($width / 2);
    border-#{$rdirection}-width: round($width / 2);
    border-top-width: 0;
    border-bottom-width: $height;
    border-bottom-color: $color;
  } @else {
    // $direction == down
    border-#{$ldirection}-width: round($width / 2);
    border-#{$rdirection}-width: round($width / 2);
    border-top-width: $height;
    border-bottom-width: 0;
    border-top-color: $color;
  }
}

/* Mixin - Arrow_v2
   ========================================================================== */
/*
 * @params
 *  $color
 *  $width
 *  $height
 *  $direction
 */
@mixin arrow-pointer($color: $color--black, $width: 6px, $height: 6px, $direction: down) {
  height: $height;
  width: $width;
  position: absolute;
  top: 0;
  bottom: 0;
  transition: transform 0.2s;
  margin: auto;
  border: 2px solid $color--transparent;
  border-radius: 2px;
  border-color: $color--transparent $color $color $color--transparent;
  @if $direction == left {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  } @else if $direction == right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  } @else if $direction == up {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  } @else {
    // $direction == down
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/* Mixin - EQ
   ========================================================================== */
/*
 * @params
 *  $state
 */

@mixin eq($state) {
  &[data-eq-state='#{$state}'] {
    @content;
  }
}

@mixin bg-img() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin bg-img--expand() {
  position: absolute;
  top: 0;
  bottom: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
}

@mixin antialiased() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin abs-pos() {
  position: absolute;
  @include swap_direction(margin, 0);
  clear: none;
  display: block;
  z-index: $z-low;
}

@mixin fontsize-rem($px, $root-px: $root-typesize) {
  font-size: $px;
  font-size: #{$px / $root-px}rem;
}

@mixin margin-bottom-rem($px, $root-px: $root-typesize) {
  margin-bottom: $px;
  margin-bottom: #{$px / $root-px}rem;
}

@mixin retina-image() {
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin cr18-shade--disabled {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 1px),
    $color--gray--lighter,
    transparent calc(50% + 1px)
  );
  background: -ms-linear-gradient(top right, transparent 48%, $color--gray--lighter, transparent 52%);
}

@mixin cr18-shade--disabled-ie11($startPoint, $endPoint) {
  background: linear-gradient(to top right, transparent $startPoint, $color--gray--lighter, transparent $endPoint);
}

// line height function to generate unitless numbers vs the px values in the styleguide
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}

// rem function to convert pixels to rem
@function get-rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}
